@import '../../theme/core.scss';

.header {
  background: #000;
  padding: 20px 0;

  .container {
    display: flex;
    justify-content: space-between;
  }
  .admin_container {
    max-width: 100%;
    padding: 0 50px;
  }
}
.logo {
  color: #fff;
}
.menu_button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:before,
  &:after {
    width: 40px;
    height: 1px;
    display: block;
    content: ' ';
    background: #fff;
    margin: 5px 0;
  }
}
.menu_logout {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
}
.login {
  color: #fff;
  text-decoration: none;
}
.login_name {
  margin-right: 10px;
}
.login:hover,
.login:focus {
  text-decoration: none;
}
