@import './theme/core.scss';

/**
 * ----------------------------------------
 * Base Styles
 * ----------------------------------------
 */

html {
  font-size: 16px; /* base font size */

  @include phone {
    font-size: 2.78vw; /* 16px at 576px */
  }

  @include desktop-or-more {
    font-size: 1.11vw; /* 16px at 1440px */
  }
}

body {
  font-family: $roboto, $open-san, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background: #fff;
}

/*.app {
  display: flex;
  width: 100%;
  min-height: 100vh;
}*/

.app-loader-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $dark-gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $roboto, sans-serif;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  appearance: none;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.6;
  }
}

p {
  font-family: $roboto, sans-serif;
  margin: 0;
  padding: 0;
}

a {
  font-family: $roboto, sans-serif;
  text-decoration: underline;
  color: $purple;
  &:hover {
    color: $pink;
    transition: color 0.3s;
  }
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: $light-purple;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px $light-purple;
  outline: 0 none;
}

input::-webkit-input-placeholder {
  text-overflow: 'ellipsis !important';
  color: $gray;
  font-size: 14px;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px $light-blue inset;
  transition: background-color 5000s ease-in-out 0s;
}

.field-b-b .form-control {
  border-left: none;
  border-right: none;
  border-top: none;
  padding-left: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
.field-region {
  font-size: 26px;
  border-bottom: 1px solid #ced4da;
  margin-bottom: 50px;
}
.field-region div {
  border: none;
  outline: none;
  box-shadow: none;
  padding-left: 0;

  &:focus {
    outline: none;
    box-shadow: none;
    border-bottom: none;
  }
}
hr {
  border-top: 1px solid #c0c0c0;
}

.impressions_container {
  display: flex;
  flex-wrap: wrap;
}
.impressions_col {
  flex: 1;
  width: 50%;

  input {
    border: none;
    border-bottom: 1px solid #c0c0c0;
    font-size: 52px;
    color: #c0c0c0;
    margin-bottom: 15px;
    width: 90%;
    height: 56px;

    &:hover {
      box-shadow: none;
      border: none;
      outline: none;
      border-bottom: 1px solid #c0c0c0;
    }
  }
}
.impression_count {
  line-height: 56px;
  font-size: 52px;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 15px;
}
.budget_row {
  padding: 40px 0 10px;
}
.accept_agreement {
  font-size: 12px;
  display: flex;

  input {
    margin-right: 30px;
  }
}
.btn_submit {
  background: #c0c0c0;
  color: #fff;
  font-size: 32px;
  padding: 10px 30px;
  margin-bottom: 30px;
}
.upload_zone {
  margin-top: 30px;
  padding: 30px;
  border: 1px dashed #ccc;
}

.field-region {
  min-width: 400px;
}

.field_v_number {
  .form-group {
    margin-bottom: 0;
  }
  .input-container {
    margin-bottom: 0;
  }
}

.field_value {
  flex: 1;
  .form-group {
    margin-bottom: 0;
  }
  .input-container {
    margin-bottom: 0;
  }
}

.current_vehicle {
  background: #ccc;
}
