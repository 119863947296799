@import '../../theme/core.scss';

.root {
  //flex: 1;
  //display: flex;
  //position: relative;
  //flex-direction: column;
  //background-color: $dark-gray;
  //height: '100vh';
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: rem(50);
}
.title_dashboard {
  border-bottom: 3px solid #000;
  padding: 50px 0 10px;
  margin-bottom: 30px;
  font-size: 36px;

  a {
    text-decoration: none;
    color: #000;
  }
}
.title_account_type {
  border-bottom: 3px solid #000;
  color: #888;
  padding: 50px 0 10px;
  margin-bottom: 30px;
  font-size: 36px;
  text-align: right;
}
.title_back {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid #000;
  color: #888;
  padding: 50px 0 10px;
  margin-bottom: 30px;
  font-size: 36px;
  text-align: right;
}
.map_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #888;
  padding: 50px 0 10px;
  margin-bottom: 30px;
  font-size: 36px;
  text-align: right;
}
.header {
  //background: #f3df4b;
  padding: 50px 0 20px;

  h2 {
    font-size: 40px;
  }

  .container {
    padding: 0;
  }
}
.admin_header {
  max-width: 100%;
  padding: 0 50px;
}
.admin_container {
  max-width: 100%;
  padding: 0 50px;
}
.dash_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;

  &__left {
    width: 33%;
  }

  &__right {
    width: 66%;
    flex: 1;
    padding-left: 25px;
  }

  &__admin__left {
    width: 260px;
  }
  &__admin__right {
    width: 66%;
    flex: 1;
    padding-left: 25px;
  }
}

.sub_title {
  font-size: 30px;
  font-weight: 400;
}
.sub_title_padded {
  font-size: 30px;
  font-weight: 400;
  padding: 45px 0 15px;
}
.nav_item {
  font-size: 30px;
  font-weight: 400;
  padding: 15px 0;

  a {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      color: #000;
    }

    &:after {
      display: inline-block;
      content: '';
      background-size: cover;
      background-image: url('../../assets/images/icon_arrow_right.png');
      background-position: center;
      width: 48px;
      height: 32px;
      margin-left: 24px;
    }
  }
}

.admin_nav_item {
  font-size: 24px;
  font-weight: 400;
  padding: 0;

  a {
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center;

    &:hover {
      color: #000;
    }
  }
}

.back_to_page {
  display: inline-block;
  content: '';
  background-size: cover;
  background-image: url('../../assets/images/icon_arrow_right.png');
  transform: rotateZ(180deg);
  background-position: center;
  width: 48px;
  height: 32px;
  font-size: 0;
  text-indent: 0;
}
.label_grey {
  color: #c6c6c5;
  font-size: 34px;
  margin-bottom: 20px;
}
.hr {
}

.greeting {
  color: white;
  font-size: rem(16);
  position: absolute;
  top: rem(32);
  right: rem(32);
}

.title {
  color: white;
  margin-top: rem(40);
  font-size: rem(52);
  text-align: center;
}

.description {
  color: $light-gray;
  margin-top: rem(24);
  font-size: rem(16);
  text-align: center;
}

.logo {
  width: rem(160);
  height: rem(160);
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  max-width: rem(480);
  margin-top: rem(120);
  @include phone {
    width: 100%;
    max-width: 100%;
    margin: rem(120) rem(16);
  }
}

.download {
  width: 45%;
  height: rem(50);
}

.logout {
  width: 45%;
  height: rem(50);
}

.form_controls {
  display: flex;
}

.form_field {
  //display: flex;
  margin: 30px 0 15px;
  align-items: baseline;
  flex-wrap: wrap;

  input,
  input::-webkit-input-placeholder {
    font-size: 28px;
    line-height: 3;
  }

  &_label {
    display: block;

    &_padded {
      padding: 25px 0 15px;
    }
  }

  label {
    width: 200px;
    min-width: 200px;
  }
}

.currency_input {
  border: none;
  border-bottom: 1px solid #c0c0c0;
  font-size: 52px;
  color: #c0c0c0;
  margin-bottom: 15px;
  width: 90%;
  height: 56px;
}

.price_balance {
  line-height: 56px;
  font-size: 52px;
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 15px;
  color: #c0c0c0;
}

.form_field_block {
  display: block;
}

.payment_field {
  display: block;
  align-items: baseline;
  padding-bottom: 15px;

  label {
    width: 200px;
    min-width: 200px;
  }
}

.video_item {
  width: 320px;
  height: 180px;
}
.campaign_heading {
  font-weight: 600;
  font-size: 36px;
  text-transform: capitalize;
  padding-bottom: 30px;
}
.campaign_list {
  padding-bottom: 80px;
}
.campaign_item {
  border-bottom: 2px solid #000;
  padding: 20px 0;



  &:last-child {
    border-bottom: none;
  }

  &_title {
    font-size: 32px;
    position: relative;
  }
  &_status{
    position: absolute;
    right: 0;
    top: 15px;
    display: block;
    content: ' ';
    background: #000;
    width: 12px;
    height: 12px;

    &_active{
      background: #25c711;
    }
  }
  &_budget_row {
    display: flex;

    justify-content: space-between;

    &_spacer {
      flex: 1;
    }

    &_price {
      font-size: 52px;
      font-weight: 600;
      text-align: right;
      padding-right: 15px;

      p {
        font-size: 24px;
      }
    }
  }
}

.current_campaign {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 20px;
  font-size: 18px;

  &_row {
    display: flex;
    border-bottom: 1px solid #ccc;
    align-items: center;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
    }
  }

  &_lable {
    width: 200px;
  }
}

.campaign_pay {
}
.total_pay {
  font-size: 60px;
  padding: 45px 0 15px;
}

.regions {
  display: flex;

  &_name {
    background: #ccc;
    margin-right: 5px;
    padding: 5px;
  }
}

.campaign_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;

  &_right {
    button {
      margin-right: 10px;
      text-decoration: underline;
    }
    a {
      color: #000;
    }
  }
}
.form_button_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 0 30px;

  &_right {
    button {
      margin-right: 10px;
      text-decoration: underline;
    }
    a {
      color: #000;
    }
  }
}
.pause_campaign {
  color: #fff;
  background: #000;
  padding: 10px 15px;
}

.modal_controls {
  display: flex;
  justify-content: space-between;
}
.btn {
  background: #000;
  color: #fff;
  padding: 5px 15px;
}
.profile_title {
  display: flex;
  border-bottom: 4px solid #000;
  padding: 35px 15px 15px 0;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.profile_info {
  padding-top: 15px;
}
.profile_data {
  &_row {
    font-size: 14px;
    display: flex;
    padding: 8px 0;
  }
  &_label {
    width: 120px;
  }
}

.vehicle {
  &_list {
    font-size: 16px;
  }
  &_item {
    position: relative;
    border-bottom: 1px solid #ccc;
    padding: 10px 15px;
  }
  &_row {
    display: flex;
    padding: 5px 0;

    &_label {
      width: 140px;
      flex: 0 0 140px;
    }
    &_value {
      display: flex;
      flex-wrap: wrap;

      &_item {
        margin-right: 10px;
      }
    }
  }
  &_show {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.submitButton {
  height: rem(48);
}

.option_item {
  background: #ccc;
  padding: 5px 10px;
  margin-right: 10px;
}

.form_data_row {
  border-bottom: 1px solid #ccc;
  padding: 20px 0 15px;

  span {
    width: 120px;
  }
}

.video_preview {
  position: relative;
  padding-top: 63.86861%;

  .video_back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .video_placeholder {
    position: absolute;
    left: 15.57177%;
    top: 3.4%;
    width: 26.939172%;
    height: 28.571428%;
    object-fit: cover;
  }
}

.modal {
  &_outer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
  }

  &_container {
    position: relative;
    max-width: 700px;
    background: #fff;
    padding: 15px;
  }
  &_header {
    border-bottom: 1px solid #000;
  }
  &_close {
    position: absolute;
    right: 5px;
    top: 0px;
  }
}
.preview_video {
  width: 640px;
  height: 400px;
}

.actions {
  display: flex;
  justify-content: flex-end;

  &_right {
    text-align: right;
  }
}

.driver_controls {
  padding: 50px 0 50px;
}

.data_item {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  font-size: 18px;

  &_th {
    font-weight: 600;
    padding-bottom: 15px;
  }

  &_time {
    width: 30%;
  }
  &_hour {
    width: 200px;
  }
  &_area {
    flex: 1;
  }
  &_views {
    width: 150px;
  }

  &_amount {
    width: 25%;
  }

  &_balance {
    width: 25%;
  }
  &_action {
    width: 15%;
    display: flex;
    align-items: center;
  }
  &_status {
    font-size: 12px;
    border-radius: 3px;
    background: #ff3b86;
    padding: 3px 8px;
    text-align: center;
    color: #fff;
    margin: 0 3px;
  }
  &_success {
    background: #4099f9;
  }

  &_edit {
    background: #0abb87;
  }
  &_delete {
    background: #e04040;
  }
  &_cancel {
    background: #999;
  }
}

// advertiser item
.adv_item {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;

  &_th {
    font-weight: 600;
    padding-bottom: 15px;
  }

  &_name {
    width: 20%;
  }
  &_email {
    width: 20%;
  }
  &_date {
    width: 20%;
  }
  &_company {
    width: 20%;
  }
  &_action {
    width: 15%;
    display: flex;
    align-items: center;
  }
  &_status {
    font-size: 12px;
    border-radius: 3px;
    background: #ff3b86;
    padding: 3px 8px;
    text-align: center;
    color: #fff;
    margin: 0 3px;
  }
  &_success {
    background: #4099f9;
  }

  &_cancel {
    background: #999;
  }
}
